<template>
    <div :class="klasaDiv">
        <!-- value: {{ value }}<br /> -->
        <!-- !providan: {{ !providan }}<br />
        !noTitle: {{ !noTitle }}<br />
        {{ !providan || !noTitle }} -->
        <span
            v-if="!providan && !noTitle"
            :class="izgled + ' unos-naslov-podloga'"
        >
            {{ naslov }}
        </span>
        <polje-unosa-tekst
            v-if="vrsta == 'tekst'"
            ref="PoljeUnosaTekst"
            class="mt-1"
            :naslov="naslov"
            :pretraga="pretraga"
            :dopis="dopis"
            :dugme="dugme"
            :dugmeVrsta="dugmeVrsta"
            :filter="filter"
            :desno="desno"
            :promenljiv="promenljiv"
            :velicina="velicina"
            :prepend="prepend"
            :inputType="textInputType"
            @promena-unosa="promenaUnosa"
            @pritisak-dugmeta="pritisakDugmeta"
            @enter="$emit('enter')"
            @izbor="$emit('izbor', $event)"
        />
        <polje-unosa-broj
            v-if="vrsta == 'broj'"
            ref="PoljeUnosaBroj"
            :class="klasaUnos"
            :dopis="dopis"
            :prepend="prepend"
            :naslov="naslov"
            :valuta="valuta"
            :providan="providan"
            :promenljiv="promenljiv"
            :velicina="velicina"
            @promena-unosa="promenaUnosa"
        />
        <polje-unosa-izbor
            v-if="vrsta == 'izbor'"
            ref="PoljeUnosaIzbor"
            class="mt-1"
            :naslov="naslov"
            :ponuda="radnaPonuda"
            :velicina="velicina"
            @promena-unosa="promenaUnosa"
        />
        <polje-unosa-datum
            v-if="vrsta == 'datum'"
            ref="PoljeUnosaDatum"
            class="mt-1"
            :naslov="naslov"
            :velicina="velicina"
            @promena-unosa="promenaUnosa"
        />
        <polje-unosa-dugme
            v-if="vrsta == 'dugme'"
            class="mt-1"
            :vrsta="vrstaDugmeta"
            :block="block"
            :slika="slika"
            :naslov="naslov"
            :velicina="velicina"
            @pritisak="pritisak()"
        />
    </div>
</template>

<script>
import PoljeUnosaTekst from "./Unos/PoljeUnosaTekst";
import PoljeUnosaBroj from "./Unos/PoljeUnosaBroj";
import PoljeUnosaIzbor from "./Unos/PoljeUnosaIzbor";
import PoljeUnosaDatum from "./Unos/PoljeUnosaDatum";
import PoljeUnosaDugme from "./Unos/PoljeUnosaDugme";

export default {
    name: "Unos",
    components: {
        PoljeUnosaTekst,
        PoljeUnosaBroj,
        PoljeUnosaIzbor,
        PoljeUnosaDatum,
        PoljeUnosaDugme,
    },
    props: {
        value: {
            default: null,
        },
        vrsta: {
            type: String,
            default: "tekst",
            validator: function ($$vrednost) {
                return (
                    ["tekst", "broj", "izbor", "datum", "dugme"].indexOf(
                        $$vrednost
                    ) !== -1
                );
            },
        },
        pretraga: {
            type: Array,
        },
        vrstaDugmeta: {
            type: String,
        },
        block: {
            type: Boolean,
        },
        slika: {
            type: String,
        },
        naslov: {
            type: String,
            default: "Naslov unosa",
        },
        dopis: {
            type: String,
        },
        dugme: {
            type: String,
        },
        dugmeVrsta: {
            type: String,
        },
        filter: {
            type: Boolean,
            default: true,
        },
        ponuda: {
            type: [Array, String],
            default: function () {
                return [{ vrsta: null, opis: null }];
            },
        },
        valuta: {
            type: String,
        },
        providan: {
            type: Boolean,
            default: false,
        },
        desno: {
            type: Boolean,
            default: false,
        },
        promenljiv: {
            type: Boolean,
            default: true,
        },
        velicina: {
            type: String,
        },
        prepend: {
            type: String,
            default: null,
        },
        noTitle: {
            type: Boolean,
            default: false,
        },
        textInputType: {
            type: String,
            default: "",
        },
        forceUpdate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        let iniVal = this.value;
        let debugVrsteOptions = [];
        if (this.$store.state.debug) {
            // debugVrsteOptions.push({
            //     vrsta: "UR",
            //     opis: "[DEBUG] Ulazni račun",
            // });
        }
        return {
            initialValue: iniVal,
            izgled: "unos-naslov-skriven",

            ponude: {
                vrste: [
                    { vrsta: null, opis: "Svi dokumenti" },
                    { vrsta: "PO", opis: "Ponuda" },
                    { vrsta: "PR", opis: "Predračun" },
                    { vrsta: "RE", opis: "Revers" },
                    { vrsta: "O", opis: "Otpremnica" },
                    { vrsta: "R", opis: "Račun" },
                    { vrsta: "AR", opis: "Avansni račun" },
                    { vrsta: "KR", opis: "Konačni račun" },
                    { vrsta: "M", opis: "Memorandum" },
                    { vrsta: "KPO", opis: "Knjiga paušalnih obveznika" },
                    ...debugVrsteOptions,
                ],
                statusi: [
                    { vrsta: null, opis: "Svi statusi" },
                    { vrsta: "naplacen", opis: "Naplaćeni dokumenti" },
                    { vrsta: "nenaplacen", opis: "Nenaplaćeni dokumenti" },
                    { vrsta: "otpisan", opis: "Otpisani dokumenti" },
                    { vrsta: "neotpisan", opis: "Neotpisani dokumenti" },
                    { vrsta: "urucen", opis: "Uručeni dokumenti" },
                    { vrsta: "neurucen", opis: "Neuručeni dokumenti" },
                ],
                smerovi: [
                    { vrsta: null, opis: "Svi" },
                    { vrsta: "I", opis: "Izlazni" },
                    { vrsta: "U", opis: "Ulazni" },
                ],
                stavka_stanja: [
                    { vrsta: null, opis: "Bilo koje" },
                    { vrsta: "gt0", opis: "Na stanju" },
                    { vrsta: 0, opis: "Nije na stanju" },
                ],
                stavka_dostupnost: [
                    { vrsta: null, opis: "Bilo koje" },
                    { vrsta: "true", opis: "Dostupno" },
                    { vrsta: "false", opis: "Skriveno" },
                ],
            },

            initalDataChanged: false,
        };
    },
    computed: {
        klasaDiv: function () {
            return this.providan == true ? "" : "unos-div";
        },
        klasaUnos: function () {
            return this.providan == true ? "" : "mt-1";
        },
        radnaPonuda: function () {
            if (Array.isArray(this.ponuda)) {
                return this.ponuda;
            }
            return this.ponude[this.ponuda];
        },
    },
    watch: {
        value: function () {
            this.spoljniUnos(this.value, true);
        },
    },
    methods: {
        promenaUnosa: function ($$vrednost) {
            switch (this.vrsta) {
                case "tekst":
                    this.izgled =
                        $$vrednost != ""
                            ? "unos-naslov-vidljiv"
                            : "unos-naslov-skriven";
                    this.$emit("input", $$vrednost);
                    break;
                case "broj":
                    // console.log($$vrednost)
                    // console.log($$vrednost)
                    // console.log($$vrednost >= 0)
                    if (!isNaN($$vrednost)) {
                        this.izgled =
                            $$vrednost.toString() != ""
                                ? "unos-naslov-vidljiv"
                                : "unos-naslov-skriven";
                        this.$emit("input", $$vrednost);
                    }
                    break;
                case "izbor":
                    this.izgled =
                        $$vrednost.vrsta != null
                            ? "unos-naslov-vidljiv"
                            : "unos-naslov-skriven";
                    this.$emit("input", $$vrednost.vrsta);
                    break;
                case "datum":
                    this.izgled =
                        $$vrednost != 0
                            ? "unos-naslov-vidljiv"
                            : "unos-naslov-skriven";
                    this.$emit("input", $$vrednost);
                    break;
                default:
                    break;
            }
            this.$emit("promena");
        },
        spoljniUnos: function ($$vrednost, rucno = false) {
            this.initalDataChanged = true;
            switch (this.vrsta) {
                case "tekst":
                    this.$refs.PoljeUnosaTekst.spoljniUnos(
                        $$vrednost != null ? $$vrednost.toString() : $$vrednost,
                        rucno
                    );
                    break;
                case "broj":
                    if (!isNaN($$vrednost)) {
                        this.$refs.PoljeUnosaBroj.spoljniUnos($$vrednost);
                    }
                    // this.$refs.PoljeUnosaBroj.spoljniUnos($$vrednost != null ? $$vrednost : $$vrednost, rucno);
                    break;
                case "izbor":
                    this.$refs.PoljeUnosaIzbor.spoljniUnos(
                        $$vrednost != null ? $$vrednost.toString() : $$vrednost
                    );
                    break;
                case "datum":
                    this.$refs.PoljeUnosaDatum.spoljniUnos(
                        $$vrednost != null ? $$vrednost.toString() : $$vrednost
                    );

                    break;
                default:
                    break;
            }
        },
        pritisakDugmeta: function () {
            this.$emit("pritisak-dugmeta");
        },
    },
    mounted() {
        setTimeout(() => {
            if (
                !this.initalDataChanged &&
                this.iniVal != this.value &&
                this.forceUpdate
            ) {
                console.log("ini", this.initalDataChanged, this.value);
                window.console.log("FORCE UPDATE", this.value);
                this.spoljniUnos(this.value);
            }
        }, 500);
    },
};
</script>

<style scoped>
.unos-naslov-podloga {
    font-weight: 350;
    transition: 0.2s;
    visibility: visible;
    font-size: 0.8rem;
    font-weight: 500;
}
.unos-naslov-vidljiv {
    opacity: 1;
    visibility: visible;
}
.unos-naslov-skriven {
    opacity: 0;
    visibility: hidden;
}
.unos-div {
    position: relative;
}

/* @media screen and (min-width: 1025px) {
    .unos-div {
        margin-bottom: 24px;
    }
} */
</style>
