import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
    },
    // {
    //     path: "/:mb/postavke",
    //     name: "Postavke",
    //     component: () =>
    //         import ( /* webpackChunkName: "postavke" */ "@/views/Postavke.vue")
    // },
    {
        path: "/:mb/analitickekartice",
        name: "AnalitickeKartice",
        component: () =>
            import(
                /* webpackChunkName: "analiticke kartice" */ "@/views/AnalitickeKartice.vue"
            ),
    },
    {
        path: "/:mb/pregled",
        name: "Pregled",
        component: () =>
            import(/* webpackChunkName: "pregled" */ "@/views/Pregled.vue"),
    },
    {
        path: "/:mb/posta",
        name: "Posta",
        component: () =>
            import(/* webpackChunkName: "posta" */ "@/views/Posta.vue"),
    },
    {
        path: "/:mb/dokumenti",
        name: "DocumentListView",
        component: () =>
            import(
                /* webpackChunkName: "DocumentListView" */ "@/views/DocumentListView.vue"
            ),
    },
    {
        path: "/:mb/komitenti",
        name: "Komitenti",
        component: () =>
            import(/* webpackChunkName: "komitenti" */ "@/views/Komitenti.vue"),
    },
    {
        path: "/:mb/knjiga",
        name: "Knjiga",
        component: () =>
            import(/* webpackChunkName: "knjiga" */ "@/views/Knjiga.vue"),
    },
    {
        path: "/:mb/gonzales",
        name: "GonzalesHubView",
        component: () =>
            import(
                /* webpackChunkName: "knjiga" */ "@/views/GonzalesHubView.vue"
            ),
    },
    {
        path: "/:mb/katalog",
        name: "Katalog",
        component: () =>
            import(/* webpackChunkName: "katalog" */ "@/views/Katalog.vue"),
    },
    {
        path: "/:mb/dokument",
        name: "DokumentiObrada",
        component: () =>
            import(
                /* webpackChunkName: "obrada_dok" */ "@/views/DokumentiObrada.vue"
            ),
    },
    // {
    //     path: "/:mb/novo/dokument/",
    //     name: "NovaObrada",
    //     component: () =>
    //         import ( /* webpackChunkName: "obrada_dok" */ "@/views/NovaObrada.vue")
    // },
    {
        path: "/:mb/prikaz",
        name: "DokumentiPrikaz",
        component: () =>
            import(
                /* webpackChunkName: "prikaz_dok" */ "@/views/DokumentiPrikaz.vue"
            ),
    },
    {
        path: "/:mb/komitent",
        name: "KomitentiObrada",
        component: () =>
            import(
                /* webpackChunkName: "obrada_kom" */ "@/views/KomitentiObrada.vue"
            ),
    },
    {
        path: "/:mb/stavka",
        name: "SifreObrada",
        component: () =>
            import(
                /* webpackChunkName: "obrada_kat" */ "@/views/SifreObrada.vue"
            ),
    },
    {
        path: "/:mb/postavke",
        name: "Postavke",
        component: () =>
            import(/* webpackChunkName: "postavke" */ "@/views/Postavke.vue"),
    },
    {
        path: "/:mb/efakture_debug",
        name: "ElectronicDocumentHub",
        component: () =>
            import(
                /* webpackChunkName: "electronic-dokument-hub" */ "@/views/ElectronicDocumentHub.vue"
            ),
    },
    {
        path: "/maintenance",
        name: "MaintenanceView",
        component: () =>
            import(
                /* webpackChunkName: "maintenance" */ "@/views/MaintenanceView.vue"
            ),
    },
    {
        path: "/:mb/not-found",
        name: "NotFoundView",
        component: () =>
            import(
                /* webpackChunkName: "not-found-view" */ "@/views/NotFoundView.vue"
            ),
    },
    {
        path: "/:mb/*",
        redirect: "/:mb/not-found",
    },
    {
        path: "/*",
        redirect: "/",
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
