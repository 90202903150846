<template>
    <div :class="`xbec__modal ${className}`" :data-visible="`${instant}`">
        <div
            class="xbec__modal__backdrop"
            @click="$emit('backdrop-close')"
        ></div>
        <div class="xbec__modal__window x-bg-white x-shadow-high">
            <p v-if="title" class="xbec__modal__title x-fg-light">
                {{ title }}
            </p>
            <p v-if="messageContent" class="xbec__modal__message x-fg-light">
                {{ messageContent }}
            </p>
            <slot></slot>
            <div
                v-if="actions.length > 0"
                class="xbec__action-bar c-modal-action-bar"
            >
                <div
                    class="xbec__action-bar__actions c-modal-action-bar-actions"
                >
                    <div
                        v-for="(action, i) in actions"
                        :key="`modal-action-${i}`"
                        class="xbec__button x-theme-light"
                        @click="$emit(action.eventName)"
                    >
                        <span>{{ action.caption }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalComponent",
    props: {
        title: {
            type: String,
            default: null,
        },
        messageContent: {
            type: String,
            default: null,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        instant: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: this.generateRandomHex(16),
        };
    },
    computed: {
        className: function () {
            return `js-modal-${this.id}`;
        },
    },
    methods: {
        showModal: function () {
            document.querySelector(`.${this.className}`).dataset.visible =
                "true";
        },
        hideModal: function () {
            document.querySelector(`.${this.className}`).dataset.visible =
                "false";
        },
    },
};
</script>

<style lang="scss" scoped>
.xbec__modal {
    pointer-events: none;
    position: absolute;

    .xbec__modal__backdrop {
        position: fixed;
        inset: 0 0 0 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
        opacity: 0;
    }

    .xbec__modal__window {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.3);
        min-height: 200px;
        min-width: 400px;
        z-index: 10000;
        border-radius: 20px;
        padding: 17px 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 0;
    }

    .xbec__modal__title {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .xbec__modal__message {
        line-height: 1.4;
        max-width: 400px;
    }

    *:not([class^="xbec__modal"]) + .c-modal-action-bar {
        margin-top: 20px;
    }
}

.xbec__modal[data-visible="true"] {
    pointer-events: unset;

    .xbec__modal__backdrop,
    .xbec__modal__window {
        opacity: 1;
    }

    .xbec__modal__window {
        transform: translate(-50%, -50%) scale(1);
    }
}

.c-modal-action-bar {
    margin-top: auto;
}

.c-modal-action-bar-actions {
    justify-content: flex-end;
}
</style>
