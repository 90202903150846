import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueFilterDateFormat from 'vue-filter-date-format';
import VueMatomo from 'vue-matomo';

import "../assets/css/paleta1.css"
import "../assets/css/tabela.css";
import "../assets/css/stylesheet-main.scss";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueClosable from 'vue-closable'
Vue.use(VueClosable)

import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
window.Apex.chart = { fontFamily: "Poppins" };


// GLOBAL CLASSES

import documentReader from "./documentReader.js";
Vue.prototype.$documentReader = new documentReader();

import apiCommander from "./apiCommander.js";
Vue.prototype.$apiCommander = new apiCommander();

import logger from "./logger.js";
Vue.prototype.$logger = new logger();

// GLOBAL VARIABLES



//

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;

Vue.use(VueMatomo, {
    host: 'https://analitika.rezidual.rs',
    siteId: 3,
    trackerFileName: 'analitika',
    router: router
});

Vue.filter('novac', function(amount, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
        if (typeof amount === 'undefined') {
            return '';
        }
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
})
Vue.mixin({
    methods: {
        openLinkInNewTab: function (url) {
            window.open(url, "_blank");
        },

        // x classes
        hideAllElements: function (elementSelector) {
            document
                .querySelectorAll(`${elementSelector}.x-visible`)
                .forEach((dropdownElement) =>
                    dropdownElement.classList.remove("x-visible")
                );  
        },
        toggleDropdownVisibility: function (event, dropdownElement) {
            const dropdownIsVisible =
                dropdownElement.classList.contains("x-visible");
            this.hideAllElements('.xbec__dropdown');
            if (!dropdownIsVisible) dropdownElement.classList.add("x-visible");
            event.stopPropagation();
        },
        //

        stripHTML: function (html) {
            html = html.replaceAll("<br>", "\n").replaceAll("<div>", "\n")
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        },
        showSupportModal: function (initialReason) {
            this.$root.$children[0].showSupportModal(initialReason);
        },
        // greska "Type annotations can only be used in TypeScript files." je zbog formatera, program radi bez problema
        // praviti se kao da je nema...
        generateMessage: function (context, content, severe = false, chainReference = null, eventBusMethodData = null) {
            const messageReference = {
                uniqueReference: `ur-${this.generateRandomHex(24)}`,
                chainReference: chainReference ?? `cr-${this.generateRandomHex(24)}`
            }
            this.$store.state.messages.push({
                context: context,
                content: content,
                severe: severe,
                unread: true,
                createdAt: new Date().getTime(),
                eventBusMethodData: eventBusMethodData,
                ...messageReference
            })
            return messageReference;
        },
        setDatasetToValue: function (
            element,
            datasetAttributeName,
            datasetAttributeValue
        ) {
            element.dataset[datasetAttributeName] = datasetAttributeValue;
        },
        uNovac: function(amount, sr = false, decimalCount = 2, decimal = ",", thousands = ".") {
            try {
                if (typeof amount === 'undefined' || amount == null) {
                    return null;
                }
                if (sr && isNaN(amount)) {
                    amount = amount.split('.').join("");
                    amount = amount.replace(/,/, '.');
                }
                amount = parseFloat(amount);
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                console.log(e)
            }
        },
        generateRandomHex: function (length) {
            return [...Array(length)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
        },
        bojaSlova: function(color) {

            // Variables for red, green, blue values
            var r, g, b, hsp;

            // Check the format of the color, HEX or RGB?
            if (color.match(/^rgb/)) {

                // If RGB --> store the red, green, blue values in separate variables
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

                r = color[1];
                g = color[2];
                b = color[3];
            } else {

                // If hex --> Convert it to RGB: http://gist.github.com/983661
                color = +("0x" + color.slice(1).replace(
                    color.length < 5 && /./g, '$&$&'));

                r = color >> 16;
                g = color >> 8 & 255;
                b = color & 255;
            }

            // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
            hsp = Math.sqrt(
                0.299 * (r * r) +
                0.587 * (g * g) +
                0.114 * (b * b)
            );

            // Using the HSP value, determine whether the color is light or dark
            if (hsp > 127.5) {

                return 'darkslategrey';
            } else {

                return 'var(--clr-white)';
            }
        },
        getElementFromId: function(id) {
            return document.getElementById(id);
        },
        formatDateToSerbian: function (date) {
            if (!date) return ''
            date = new Date(date.toString().length < 13 ? date * 1000 : date);
            return (
                date.getDate() +
                "." +
                (date.getMonth() + 1) +
                "." +
                date.getFullYear() +
                "."
            );
        }
    },
})

Vue.filter('rokZaIsplatu', function(item) {
    if (typeof(item.placanje || {}).rok === 'undefined') {
        return;
    }
    let vreme = new Date / 1000;
    let rok = +item.placanje.rok * 24 * 60 * 60;
    let dana = (+item.dokument.vreme + rok - vreme) / 24 / 60 / 60;
    if (dana < 0) {
        return "Rok je istekao";
        // return "Rok: istekao pre " + Math.abs(~~dana) + " dan(a)";
    } else if (0 < dana) {
        return "Rok ističe za " + ~~dana + " dan(a)";
    } else {
        return "Rok je danas";
    }
})

Vue.filter('datum', function(item) {
    let datum = item;
    let date = new Date(datum * 1000);
    return (
        date.getDate() +
        "." +
        (date.getMonth() + 1) +
        "." +
        date.getFullYear() +
        "."
    );
})

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueFilterDateFormat, {
    dayOfWeekNames: [
        'Nedelja', 'Ponedeljak', 'Utorak', 'Wednesday', 'Thursday',
        'Friday', 'Saturday'
    ],
    dayOfWeekNamesShort: [
        'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa'
    ],
    monthNames: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ],
    monthNamesShort: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");