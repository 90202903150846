<template>
    <div
        class="xbec__notification-center x-shadow-high"
        ref="notificationCenter"
        data-expanded="false"
    >
        <div
            class="xbec__notification-center__close x-theme-white x-shadow-regular"
            @click="hideNotificationCenter()"
        >
            <i class="fa-solid fa-chevron-right"></i>
        </div>
        <div
            class="xbec__notification-center__trash x-theme-white x-shadow-regular"
            @click="clearMessages()"
        >
            <i class="fa-solid fa-trash"></i>
        </div>
        <p class="xbec__notification-center__title">Poruke</p>
        <div class="xbec__notification-center__message-list">
            <div
                v-for="(chain, reference) in messageChains"
                :key="reference"
                :class="`js-message-chain-${reference} xbec__message-chain`"
            >
                <div
                    v-for="message in chain"
                    :key="message.uniqueReference"
                    :class="`js-message-${message.uniqueReference} xbec__message x-shadow-regular`"
                    @click="expandMessageChain(reference, $event)"
                >
                    <div class="xbec__message__wrapper">
                        <div
                            class="xbec__message__disclaimer"
                            v-if="message.severe"
                        >
                            <span>Važno obaveštenje</span>
                        </div>
                        <div class="xbec__message__body">
                            <i
                                :class="`fa-solid ${getMessageIconByContext(
                                    message.context
                                )} c-${message.context}`"
                            ></i>
                            <p class="xbec__message__caption">
                                {{ message.content }}
                            </p>
                        </div>
                        <div
                            v-if="message.eventBusMethodData"
                            class="xbec__message__actions"
                        >
                            <div
                                v-for="(
                                    button, i
                                ) in message.eventBusMethodData"
                                :key="`message-button-${message.uniqueReference}-${i}`"
                                class="xbec__message__button"
                                @click="emitMethodToEventBus(button.methodName)"
                            >
                                {{ button.caption }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="xbec__button x-theme-white x-shadow-regular"
                    @click="collapseMessageAllChains()"
                >
                    <span>uskupi</span>
                </div>
            </div>
        </div>
        <div
            v-if="messages.length == 0"
            class="xbec__notification-center__placeholder x-shadow-regular"
        >
            <span>nema obaveštenja</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationCenterComponent",
    data() {
        return {
            messageHistory: [],
            updatedDOMTimeout: null,
        };
    },
    computed: {
        messages: function () {
            return [...this.$store.state.messages].sort((m1, m2) => {
                return m2.createdAt - m1.createdAt;
            });
        },
        messageChains: function () {
            return this.messages.reduce((messageChains, message) => {
                const { chainReference } = message;
                messageChains[chainReference] =
                    messageChains[chainReference] || [];
                messageChains[chainReference].push(message);
                messageChains[chainReference] = messageChains[
                    chainReference
                ].sort((m1, m2) => {
                    return m2.createdAt - m1.createdAt;
                });
                return messageChains;
            }, {});
        },
    },
    // watch: {
    //     messages: function () {
    //         this.updateMessageList();
    //     },
    // },
    methods: {
        emitMethodToEventBus: function (methodName) {
            window.console.log("EMITOVANO");
            this.$eventBus.$emit(methodName);
        },
        getMessageIconByContext: function (context) {
            switch (context) {
                case "success":
                    return "fa-circle-check";
                case "awaiting":
                    return "fa-hourglass-half";
                case "failure":
                    return "fa-triangle-exclamation";
                case "support":
                    return "fa-comments";
                case "debug":
                    return "fa-bug";
                default:
                    break;
            }
        },
        updateMessageList: function () {
            window.console.log("update message list");
            let messageChain,
                messageChainElement,
                messageElement,
                latestMessageInChainHeight;
            for (messageChain in this.messageChains) {
                messageChainElement = document.querySelector(
                    `.js-message-chain-${messageChain}`
                );
                messageChainElement.classList.remove("x-expanded");
                this.messageChains[messageChain].forEach((message, i) => {
                    messageElement = document.querySelector(
                        `.js-message-${message.uniqueReference}`
                    );
                    if (messageElement === null) return;
                    if (i == 0) {
                        messageElement.style.top = "0px";
                        if (
                            this.$refs.notificationCenter.dataset.expanded ===
                                "false" &&
                            !this.messageHistory.includes(
                                message.uniqueReference
                            )
                        ) {
                            messageElement.style.transform =
                                "translateX(-118%)";
                            setTimeout(() => {
                                this.centerMessage(message.uniqueReference);
                            }, 7000);
                        } else {
                            messageElement.style.transform = "translateX(0%)";
                        }
                        latestMessageInChainHeight =
                            messageElement.offsetHeight;
                        messageElement.style.height =
                            messageChainElement.style.height = `${latestMessageInChainHeight}px`;
                        messageChainElement.style.marginBottom = `0px`;
                    } else if (i == 1 || i == 2) {
                        messageElement.style.top = `${
                            latestMessageInChainHeight - 44 + i * 10
                        }px`;
                        messageElement.style.transform = `scale(0.9${
                            10 - 5 * i
                        })`;
                        messageElement.style.height = `44px`;
                        messageChainElement.style.marginBottom = `${i * 10}px`;
                    } else {
                        messageElement.style.top = "0px";
                        messageElement.style.opacity = "0";
                    }
                    this.messageHistory.push(message.uniqueReference);
                });
            }
        },
        expandMessageChain: function (chainReference, event) {
            if (
                Array.from(event.target.classList).includes(
                    "xbec__message__button"
                )
            ) {
                return;
            }
            this.collapseMessageAllChains();
            let messageChainElement = document.querySelector(
                `.js-message-chain-${chainReference}`
            );
            if (messageChainElement === null) return;
            messageChainElement.classList.add("x-expanded");
            this.expandMessageList(messageChainElement);
        },
        expandMessageList: function (chainElement) {
            let previousMessageEnd,
                totalMessageChainHeight = 0;
            chainElement.style.height = "0px";
            // if (Array.from(chainElement.children).length > 1)
            Array.from(chainElement.children).forEach((messageElement, i) => {
                if (
                    Array.from(messageElement.classList).includes(
                        "xbec__button"
                    )
                )
                    return;
                messageElement.style.opacity = "1";
                messageElement.style.height = "fit-content";
                if (i > 0) {
                    messageElement.style.top = `${previousMessageEnd + 10}px`;
                    previousMessageEnd =
                        messageElement.offsetHeight +
                        Number(
                            messageElement.style.top.substring(
                                0,
                                messageElement.style.top.length - 2
                            )
                        );
                } else {
                    messageElement.style.top = "40px";
                    previousMessageEnd = 40 + messageElement.offsetHeight;
                }
                totalMessageChainHeight = previousMessageEnd + 10;
            });
            chainElement.style.height = `${totalMessageChainHeight}px`;
        },
        collapseMessageAllChains: function () {
            window.console.log("collapseMessageAllChains");
            this.updateMessageList();
        },
        hideNotificationCenter: function () {
            this.setAllMessagesAsRead();
            this.$refs.notificationCenter.dataset.expanded = "false";
        },
        showNotificationCenter: function () {
            this.setAllMessagesAsRead();
            this.centerExternalMessages();
            this.$refs.notificationCenter.dataset.expanded = "true";
        },
        clearMessages: function () {
            this.setAllMessagesAsRead();
            this.$store.state.messages = [];
        },
        setAllMessagesAsRead: function () {
            for (let message of this.$store.state.messages) {
                message.unread = false;
            }
        },
        centerExternalMessages: function () {
            document
                .querySelectorAll(".xbec__message")
                .forEach((messageElement) => {
                    this.centerMessage(messageElement);
                });
        },
        centerMessage: function (uniqueReference) {
            let messageElement =
                typeof uniqueReference === "string"
                    ? document.querySelector(`.js-message-${uniqueReference}`)
                    : uniqueReference;
            if (messageElement.style.transform == "translateX(-118%)")
                messageElement.style.transform = "translateX(0%)";
        },
    },
    updated: function () {
        clearTimeout(this.updatedDOMTimeout);
        this.updatedDOMTimeout = setTimeout(() => {
            window.console.log("updated - to");
            this.updateMessageList();
        }, 100);
    },
};
</script>

<style lang="scss" scoped>
.xbec__notification-center {
    position: fixed;
    inset: 0 0 0 auto;
    z-index: 10000;
    width: 350px;
    background: rgba(238, 243, 249, 0.633);
    -webkit-backdrop-filter: blur(6px);
    -o-backdrop-filter: blur(6px);
    -moz-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    // pointer-events: none;
    transform: translateX(100%);

    .xbec__notification-center__close {
        right: 300px;
        font-size: 1.2rem;
    }

    .xbec__notification-center__trash {
        right: 20px;
    }

    .xbec__notification-center__close,
    .xbec__notification-center__trash {
        position: fixed;
        top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        aspect-ratio: 1;
        border-radius: 10px;
        cursor: pointer;

        &:hover i {
            color: var(--clr-primary-500);
        }
    }

    .xbec__notification-center__title {
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--clr-primary-900);
    }

    .xbec__notification-center__message-list {
        width: 100%;
    }
}

.xbec__message-chain {
    position: relative;
    cursor: pointer;

    .xbec__button {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100vw;
        opacity: 0;
        z-index: 9995;
    }

    & + & {
        margin-top: 30px;
    }

    .xbec__message:nth-of-type(1) {
        position: absolute;
        // top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
        transform: translateX(100%);
    }

    .xbec__message:nth-of-type(2) {
        position: absolute;
        // top: 7.5px;
        left: 0;
        width: 100%;
        z-index: 9998;
        opacity: 0.8;
        height: 44px;

        * {
            display: none;
        }
    }

    .xbec__message:nth-of-type(3) {
        position: absolute;
        // top: 14px;
        left: 0;
        width: 100%;
        z-index: 9997;
        opacity: 0.6;
        height: 44px;

        * {
            display: none;
        }
    }

    .xbec__message:nth-of-type(n + 4) {
        display: none;
        z-index: 9996;
    }
}

.xbec__message {
    // opacity: 0;
    background-color: var(--clr-white);
    border-radius: 15px;
    height: fit-content;

    &.x-visible {
        opacity: 1;
        transform: translateX(0%);
        transition: all 0s !important;
    }

    &.x-incoming {
        opacity: 1;
        transform: translateX(0%);
        transition: all 300ms linear;
    }
}

.xbec__message__wrapper {
    position: relative;
}

.xbec__message__body {
    display: flex;
    gap: 20px;
    padding: 10px 13px;
    align-items: center;

    p {
        margin: 0;
        color: var(--clr-primary-900);
    }

    i {
        &.c-success {
            color: var(--clr-green-500);
        }

        &.c-awaiting,
        &.c-failure {
            color: var(--clr-yellow-500);
        }

        &.c-support {
            color: var(--clr-gray-600);
        }

        &.c-debug {
            color: var(--clr-primary-500);
        }
    }
}

.xbec__message__actions {
    padding: 5px 13px 10px 48px;

    .xbec__message__button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        padding: 3px 8px;
        background-color: var(--clr-gray-300);
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: var(--clr-gray-500);
        }
    }
}

.xbec__message__disclaimer {
    height: 25px;
    background-color: var(--clr-gray-100);
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--clr-gray-600);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px 15px 0 0;
}

.xbec__message-chain.x-expanded {
    .xbec__button {
        opacity: 1;
    }

    .xbec__message {
        display: unset;
        position: absolute;
        transform: scale(1) !important;

        .xbec__message__body {
            display: flex;
        }

        * {
            display: unset;
        }

        .xbec__message__disclaimer {
            display: flex;
        }

        .xbec__message__actions {
            display: block;
        }

        .xbec__message__button {
            display: inline-flex;
        }
    }
}

.xbec__notification-center__placeholder {
    border-radius: 100vw;
    font-size: 0.8rem;
    padding: 3px 9px;
    font-weight: 500;
    background-color: var(--clr-white);
    color: var(--clr-gray-600);
    user-select: none;
}

.xbec__notification-center[data-expanded="true"] {
    transform: translateX(0%);
    pointer-events: all;
    // overflow: visible;
}
</style>