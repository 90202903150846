<template>
    <aside id="main-sidebar" :class="expansionEnabled ? 'expandable' : ''">
        <div id="logo">
            <img src="../../../assets/logo.svg" alt="Logo" />
        </div>
        <div class="sidebar-section">
            <a
                @click.prevent="pageNavigate('Pregled')"
                :class="`sidebar-section-row ${
                    $route.name == 'Pregled' ? 'active' : ''
                }`"
                :href="`/${$route.params.mb}/pregled`"
            >
                <i class="fa-solid fa-chart-pie"></i>
                <span>Pregled</span>
            </a>
            <a
                @click.prevent="pageNavigate('DocumentListView')"
                :class="`sidebar-section-row ${
                    $route.name == 'DocumentListView' ? 'active' : ''
                }`"
                :href="`/${$route.params.mb}/dokumenti`"
            >
                <i class="fa-solid fa-folder-open"></i>
                <span>Dokumenti</span>
            </a>
            <a
                @click.prevent="pageNavigate('Komitenti')"
                :class="`sidebar-section-row ${
                    $route.name == 'Komitenti' ? 'active' : ''
                }`"
                :href="`/${$route.params.mb}/komitenti`"
            >
                <i class="fa-solid fa-address-book"></i>
                <span>Komitenti</span>
            </a>
            <a
                @click.prevent="pageNavigate('Katalog')"
                :class="`sidebar-section-row ${
                    $route.name == 'Katalog' ? 'active' : ''
                }`"
                :href="`/${$route.params.mb}/katalog`"
            >
                <i class="fa-solid fa-box"></i>
                <span>Katalog</span>
            </a>
            <a
                @click.prevent="pageNavigate('AnalitickeKartice')"
                :class="`sidebar-section-row ${
                    $route.name == 'AnalitickeKartice' ? 'active' : ''
                }`"
                :href="`/${$route.params.mb}/analitickekartice`"
            >
                <i class="fa-solid fa-diagram-next"></i>
                <span>Analitičke kartice</span>
            </a>
            <a
                @click.prevent="pageNavigate('GonzalesHubView')"
                :class="`sidebar-section-row ${
                    $route.name == 'GonzalesHubView' ? 'active' : ''
                }`"
                :href="`/${$route.params.mb}/analitickekartice`"
            >
                <i class="fa-solid fa-g"></i>
                <span>Gonzales</span>
            </a>
        </div>
        <div class="sidebar-section">
            <a
                @click.prevent="pageNavigate('Postavke')"
                :class="`sidebar-section-row ${
                    $route.name == 'Postavke' ? 'active' : ''
                }`"
                :href="`/${$route.params.mb}/postavke`"
            >
                <i class="fa-solid fa-gear"></i>
                <span>Postavke subjekta</span>
            </a>
            <div @click="$emit('ps')" class="sidebar-section-row">
                <i class="fa-solid fa-briefcase"></i>
                <span>Promeni subjekat</span>
            </div>
            <div @click="showSupportModal()" class="sidebar-section-row">
                <i class="fa-solid fa-comments"></i>
                <span>Tehnička podrška</span>
            </div>
            <div @click="$emit('po')" class="sidebar-section-row">
                <i class="fa-solid fa-right-from-bracket"></i>
                <span>Odjavi se</span>
            </div>
        </div>
        <div class="sidebar-section">
            <div
                @click="closeSidebarOnMobile()"
                id="main-sidebar-close-button"
                class="sidebar-section-row"
            >
                <i class="fa-solid fa-chevron-left"></i>
                <span>Zatvori traku</span>
            </div>
            <div @click="toggleDisplayColorMode()" class="sidebar-section-row">
                <i class="fa-solid fa-moon"></i>
                <span>Promeni režim zaklona</span>
            </div>
            <div
                @click="expansionEnabled = !expansionEnabled"
                class="sidebar-section-row"
            >
                <i class="fa-solid fa-lock"></i>
                <span>Spreči širenje</span>
            </div>
        </div>
    </aside>
</template>

<script>
export default {
    data() {
        return {
            expansionEnabled: true,
            colorMode: false,
        };
    },
    methods: {
        test: function () {
            this.generateMessage(
                "failure",
                `Opcija je trenutno u razvoju...`,
                false
            );
        },
        pageNavigate: function (routeName) {
            this.closeSidebarOnMobile();
            this.$router.push({
                name: routeName,
                params: {
                    mb: this.$route.params.mb,
                },
            });
        },
        toggleDisplayColorMode: function () {
            window.console.log(document.documentElement.style.filter);
            document.documentElement.style.filter =
                document.documentElement.style.filter == "invert(1)"
                    ? "none"
                    : "invert(1)";
            // this.colorMode = !this.colorMode;
            // if (this.colorMode) {
            //     document.documentElement.classList.add("x-dark-mode");
            //     document.documentElement.classList.remove("x-light-mode");
            // } else {
            //     document.documentElement.classList.add("x-light-mode");
            //     document.documentElement.classList.remove("x-dark-mode");
            // }
        },
        openSidebarOnMobile: function () {
            document.getElementById(`main-sidebar`).classList.add(`visible`);
        },
        closeSidebarOnMobile() {
            this.$root.$children[0].useState = "idle";
            document.getElementById(`main-sidebar`).classList.remove(`visible`);
        },
    },
    mounted: function () {
        document.documentElement.classList.add("x-light-mode");
        document.documentElement.classList.remove("x-dark-mode");
    },
};
</script>

<style lang="scss" scoped>
#main-sidebar {
    user-select: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 70px;
    background: rgba(255, 255, 255, 0.55);
    -webkit-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    transition: width 0.35s ease-out;
    overflow: hidden;
    z-index: 999;

    &.expandable:hover {
        width: 300px;
    }

    @media (max-width: 768px) {
        display: none;

        &.visible {
            display: block;
            width: 300px;
        }
    }

    @media (max-width: 400px) {
        display: none;

        &.visible {
            width: 100vw;
        }
    }

    #logo {
        width: calc(100% - 16px);
        overflow: hidden;
        display: block;
        margin: 8px 8px 15px 8px;

        img {
            height: 68px;
        }
    }

    .sidebar-section:last-of-type {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .sidebar-section {
        height: fit-content;
        width: calc(100% - 20px);
        padding: 5px;
        margin: 10px auto;
        background-color: var(--clr-white);
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.07);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.07);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.07);

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        overflow: hidden;

        .sidebar-section-row {
            position: relative;
            color: var(--clr-gray-500);
            border-radius: 5px;
            text-decoration: none;
            // white-space: nowrap;
            align-items: center;
            display: flex;
            gap: 5px;
            margin: 5px 0;
            cursor: pointer;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &:hover::before {
                position: absolute;
                content: "";
                top: 50%;
                left: -10px;
                border-radius: 50%;
                transform: translate(0, -50%);
                height: 10px;
                width: 10px;
                background-color: var(--clr-gray-500);
            }

            &.active::before {
                position: absolute;
                content: "";
                top: 50%;
                left: -10px;
                border-radius: 5px;
                transform: translate(0, -50%);
                height: 30px;
                width: 10px;
                background-color: var(--clr-primary-500);
            }

            &#main-sidebar-close-button {
                display: none;

                @media (max-width: 768px) {
                    display: flex;
                }
            }

            i {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                height: 40px;
                width: 40px;
                font-size: 1.4rem;
            }

            span {
                font-size: 1.1rem;
                // height: 40px;
                line-height: 40px;
                font-weight: 500;
                width: calc(100% - 40px);
                white-space: nowrap;
            }
        }
    }
}
</style>